<template>
  <!-- 报名信息填写页面 -->
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/study/study">学习中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">报名信息</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!-- 信息填写 -->
    <div class="write-panel">
      <!-- 注意事项 -->
      <div class="tips-note">
        <p>1、以下信息将打印在您的证书上，请仔细填写。</p>
        <p>2、如无工作单位，请填写“个人"。</p>
        <p>3、仅限本人自己报名，不得替他人报名，如经发现，取消考试资格</p>
      </div>
      <!-- 基本信息 -->
      <div class="side-item">
        <h4 class="title">基本信息：</h4>
        <div class="personnel-info">
          <div class="personnel-pic" @click="onclickImg(1)">
            <div class="upload-pic" v-if="!certifiMobile">
              <img src="@/assets/image/study/pic.png" alt="">
              <p class="tips">请上传1寸蓝底或白底电子照片，尺寸为2.5cm×3.5cm</p>
            </div>
            <img class="user-pic" v-else :src="certifiMobile" alt="">
          </div>
          <div class="personnel-detail">
            <div class="li-item">
              <span class="li-title">姓名：</span>
              <a-input class="li-input" :disabled="true" v-model="name" />
            </div>
            <div class="li-item">
              <span class="li-title">身份证号：</span>
              <a-input class="li-input" :disabled="true" v-model="idcard" />
            </div>
            <div class="li-item">
              <span class="li-title">性别：</span>
              <a-radio-group v-model="sex" :disabled="true" name="radioGroup">
                <a-radio :value="1">男</a-radio>
                <a-radio :value="0">女</a-radio>
              </a-radio-group>
            </div>
            <div class="li-item">
              <span class="li-title">学历：</span>
              <a-select
                class="li-input"
                :disabled="isOnly == 2"
                placeholder="请选择学历"
                v-model="educationIndex"
              >
                <a-select-option v-for="(item,index)  in educationColumns" @click="oneducationConfirm(item)" :key="index" :value="index">{{item.name}}</a-select-option>
              </a-select>
            </div>
            <div class="li-item">
              <span class="li-title">出生日期：</span>
              <a-date-picker class="li-input" :disabled="true" placeholder="请选择出生日期" valueFormat="YYYY-MM-DD" v-model="birthday" />
            </div>
            <div class="li-item">
              <span class="li-title">职称/职务：</span>
              <a-select
                class="li-input"
                v-model="workIndex"
                :disabled="isOnly == 2"
                placeholder="请选择职称/职务"
              >
                <a-select-option v-for="(item,index) in workColumns" :key="index" @click="onworkConfirm(item)" :value="index">{{item.name}}</a-select-option>
              </a-select>
            </div>
            <div class="li-item">
              <span class="li-title">联系方式：</span>
              <a-input class="li-input" :disabled="true" v-model="mobile" />
            </div>
            <!-- <div class="li-item">
              <span class="li-title">工作单位：</span>
              <a-select
                class="li-input"
                mode="combobox"
                placeholder="请输入工作单位"
                :disabled="isOnly == 2"
                v-model="workUnit"
                @change="getWorkList"
              >
                <a-select-option v-for="(item,index) in workArr" :key="index" @click="onUnitConfirm(item)" :value="item.name">{{item.name}}</a-select-option>
              </a-select>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 身份认证 -->
      <div class="side-item">
        <h4 class="title">身份认证：</h4>
        <div class="idcard-info">
          <div class="idcard-item">
            <p class="types-name"><i>1</i>国徽页</p>
            <p class="rubric">上传您身份证国徽面</p>
            <!-- 图片 -->
            <div class="idcard-cover" @click="onclickImg(2)">
              <img class="idcard-img" v-if="reversePicture" :src="reversePicture" alt="">
              <img class="idcard-img" v-else src="@/assets/image/study/idcard1.png" alt="">
            </div>
          </div>
          <div class="idcard-item">
            <p class="types-name"><i>2</i>头像页</p>
            <p class="rubric">上传您身份证头像面</p>
            <!-- 图片 -->
            <div class="idcard-cover" @click="onclickImg(3)">
              <img class="idcard-img" v-if="frontPicture" :src="frontPicture" alt="">
              <img class="idcard-img" v-else src="@/assets/image/study/idcard2.png" alt="">
            </div>
          </div>
          <div class="idcard-item">
            <p class="types-name"><i>3</i>手持身份证照片</p>
            <div class="hold-pic">
              <p class="rubric">人脸、身份证上的信息需清晰可见</p>
              <p class="rubric">必须本人手持身份证</p>
            </div>
            <!-- 图片 -->
            <div class="idcard-cover last-idcard-cover" @click="onclickImg(4)">
              <img class="idcard-img" v-if="heldPhotos" :src="heldPhotos" alt="">
              <img class="idcard-img" v-else src="@/assets/image/study/idcard3.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 提交按钮 -->
      <div class="submit-btn">
        <span class="cancel-btn" @click="$router.go(-1)">{{isOnly == 2 ? '返回' : '取消'}}</span>
        <span class="all-btn-small" @click="onSubmitVerify()" v-if="isOnly != 2">确认</span>
      </div>
      <!-- 加载动态 -->
      <a-spin class="sping" v-show="uploading || loading" size="large" />
    </div>
    <!-- 上传图片 -->
    <input
      type="file"
      ref="file"
      @change="uploadImg"
      accept="image/jpg, image/png, image/jpeg"
      style="display: none"
    />

    <!-- 考试报名弹窗 -->
    <TipsModal 
      :isCancelBtnShow="false"
      ConfirmBtnText="我知道了"
      :isPublicTipsShow="showPopup" 
      :tipsText="popupTxt"
      @setEvent="signUpOk" />

    <!-- 补考弹窗 -->
    <TipsModal 
      :isCancelBtnShow="true"
      :maskClosable="false"
      ConfirmBtnText="去支付"
      :isPublicTipsShow="confirmPopup" 
      :tipsText="confirmTxt"
      @setEvent="onSubmit" />
  </div>
</template>

<script>
import { uploadObject } from "@/unit/cosUpload.js";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      uploading: false,
      configId: 0, // 考试id
      timeId: 0, // 场次id
      signupId: 0, // 报名id
      signupUserId: 0, // 用户报名id
      upImgType: 0, //上传图片方式 1证件照 2国徽面 3头像面 4手持身份证
      type: 1, //1报名 2修改 3详情
      lock:false, // 防抖锁
      orderId: 0,
      payLoad:false,
      isOnly: '', // 信息只读

      /* 报名基础信息 */
      certifiMobile: '', //证件照片
      name: '', //姓名
      sex: '', //性别
      birthday: '', //出生日期
      mobile: '', //联系方式
      idcard: '', //身份证号
      education: "", //学历
      educationId: '', //学历id
      post: '', //职称/职务
      workUnit: '', //工作单位
      workunitId: '', //工作单位id
      reversePicture: '', //身份证反面照片
      frontPicture: '', //身份证正面照片
      heldPhotos: '', //手持身份证照片

      educationColumns: [], // 学历
      educationIndex: undefined, // 学历索引
      workColumns: [], // 职位职称
      workIndex: undefined,
      workArr:[], // 工作单位

      isMake: false, // 是否需要补考
      submitParams: {}, // 报名信息
      showPopup:false,
      popupTxt: '',
      auditTime:'', // 考试审核时间，
      confirmPopup: false,
      confirmTxt: '', // 补考支付文案

      orgId:0, //机构id
      payStatus: 0, // 机构补考支付状态
    }
  },
  // 事件处理器
  methods: {
    // 报名基础信息详情
    getSignupDetail() {
      let url = ''
      let params = {}
      if(this.type == 1){
        url = '/hxclass-pc/exam/user/last-signup-info'
        params = {
          userId: this.$store.state.userInfo.userId
        }
      } else {
        url = '/hxclass-pc/exam/user-signup-info'
        params = {
          signupUserId: this.signupUserId
        }
      }
      this.$ajax({
        url: url,
        params: params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 回显报名信息
          if (res.data !== null) {
            this.echoData(res.data)
            // this.getWorkList(res.data.workUnit)
          } else {
            // 获取基础信息
            this.getBasics()
          }
        }
        this.loading = false
      });
    },
    // 无报名信息查个人详情信息
    getBasics() {
      this.$ajax({
        url: "/hxclass-pc/user/info/infodetail",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.name = res.data.name //姓名
          // 性别 全部改为int类型
          if (res.data.sex == '2' || res.data.sex == 2) {
            this.sex = 0
          } else {
            this.sex = parseInt(res.data.sex)
          }
          this.birthday = res.data.birthday || this.nowTime//出生日期
          this.currentDate = res.data.birthday || this.nowTime, //当前时间
          // let birthday = this.birthday ? this.birthday.split('-') : this.nowTime.split('-')
          // this.currentDate = new Date(birthday[0], birthday[1]-1, birthday[2]), //当前时间
          this.mobile = res.data.mobile //联系方式
          this.idcard = res.data.idNo //身份证号
          this.education = res.data.degree //学历
          this.educationId = res.data.degreeCode //学历id
          this.educationIndex = this.educationColumns.findIndex((item)=>{return item.name==this.education}) //学历索引
          if(this.educationIndex < 0 && !res.data.degree){
            this.educationIndex = undefined
          } else if (this.educationIndex < 0) {
            this.educationIndex = res.data.degree
          }
          // this.workUnit = res.data.work //工作单位
          // this.workUnitId = res.data.workCom //工作单位id
          // this.workunitId = res.data.workCom //工作单位id
          this.getWorkList(res.data.work)
        } else {

        }
      });
    },

    // 回显报名信息
    echoData(data) {
      this.certifiMobile = data.certifiMobile  //证件照片
      this.name = data.name  //姓名
      // 性别  全部改为int类型 
      if (data.sex == '2' || data.sex == 2) {
        this.sex = 0
      } else {
        this.sex = parseInt(data.sex) //性别
      }
      this.birthday = data.birthday //出生日期
      this.mobile = data.mobile //联系方式
      this.idcard = data.idcard //身份证号
      this.education = data.education  //学历
      // 博士后(处理老数据)
      if (this.education == '博士研究生') {
        this.education = '博士后'
      }
      this.educationIndex = this.educationColumns.findIndex((item)=>{return item.name==this.education}) //学历索引
      if(this.educationIndex < 0 && !data.education){
        this.educationIndex = undefined
      } else if (this.educationIndex < 0) {
        this.educationIndex = data.education
      }
      this.educationId = data.educationId //学历编码
      this.post = data.post //职称/职务
      this.workIndex = this.workColumns.findIndex((item)=>{return item.name==this.post}) //工作单位索引
      if(this.workIndex < 0  && !data.post){
        this.workIndex = undefined
      } else if (this.workIndex < 0) {
        this.workIndex = data.post
      }
      // this.workUnit = data.workUnit //工作单位
      // this.workunitId = data.workunitId //工作单位id
      this.reversePicture = data.reversePicture //身份证反面照片
      this.frontPicture = data.frontPicture //身份证正面照片
      this.heldPhotos = data.heldPhotos //手持身份证照片


      this.orgId = data.orgId
      this.payStatus = data.payStatus
    },

    //点击图片唤起本地文件夹
    onclickImg(type) {
      if(this.uploading){
        this.$message.warning('图片正在上传中，请稍后重试');
        return
      }
      if(this.isOnly == 2){
        return
      }
      this.upImgType = type
      this.$refs.file.value=null
      this.$refs.file.click()
    },

    uploadImg(e){
      if (!this.$regular.imgReg.reg.test(e.target.files[0].name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      let _this = this;
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      _this.uploading = true

      // 腾讯云对象存储上传文件
      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      uploadObject(file, bucket, _this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          if (_this.upImgType == 1){  // 证件照
            _this.certifiMobile = url
          } else if (_this.upImgType == 2) {  // 国徽页
            _this.reversePicture = url
          } else if (_this.upImgType == 3) {  // 头像页
            _this.frontPicture = url
          } else if (_this.upImgType == 4) {  // 手持身份证
            _this.heldPhotos = url
          }
        } else {
          _this.$message.error("上传失败");
        }
        _this.uploading = false
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    
    // 学历列表
    getEducation() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/nation/degree/list",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.educationColumns = res.data
        } else {
          this.loading = false
        }
        this.getWork()
      });
    },
    // 职务列表
    getWork() {
       this.$ajax({
        url: "/hxclass-pc/job/list/all",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.workColumns = res.data
        } else {
          this.loading = false
        }

        this.getSignupDetail()
      });
    },
    // 工作单位列表
    getWorkList(keyword) {
      if(keyword){
        clearTimeout(this.tiemID);
        this.tiemID = setTimeout(() => {
          // 工作单位列表
          this.$ajax({
            url: "/hxclass-pc/work/sort/list",
            params: {
              keyword: keyword
            }
          }).then((res) => {
            if (res.code == 200 && res.success) {
              let workArr = []
              res.data.map((item, index) => {
                workArr.push(...item.sortDetailVOList)
              })
              this.$set(this, 'workArr', workArr)
            }
          });
        }, 500);
      } else {
        this.$set(this, 'workArr', [])
      }
    },

    // 选择学历
    oneducationConfirm(e) {
      this.education = e.name;
      this.educationId = e.code
    },

    // 选择职位职称
    onworkConfirm(e) {
      this.post = e.name;
    },

    // 选择工作单位
    onUnitConfirm(e) {
      this.workUnit = e.name;
      this.workunitId = e.workUnitId
    },

    // 提交校验
    onSubmitVerify() {
      if (this.loading) {
        return
      }

      // 判空校验-证件照
      if (!this.certifiMobile) {
        this.$message.error('请上传证件照');
        return
      }
      // 学历
      if (!this.education) {
        this.$message.error('请选择学历');
        return
      }
      // 出生日期
      if (!this.birthday) {
        this.$message.error('请选择出生日期');
        return
      }
      // 职称/职务
      if (!this.post) {
        this.$message.error('请选择职称/职务');
        return
      }
      // 工作单位
      // if (!this.workUnit) {
      //   this.$message.error('请选择工作单位');
      //   return
      // }
      // 国徽面
      if (!this.reversePicture) {
        this.$message.error('请上传身份证国徽页照片');
        return
      }
      // 头像面
      if (!this.frontPicture) {
        this.$message.error('请上传身份证头像页照片');
        return
      }
      // 手持身份证
      if (!this.heldPhotos) {
        this.$toast('请上传手持身份证照片')
        return
      }
      
      
      let params = {}
      params.birthday = this.birthday //出生日期
      params.post = this.post //职称/职务
      params.education = this.education //学历
      params.educationId = this.educationId //学历id
      params.idcard = this.idcard //身份证号
      params.mobile = this.mobile //联系方式
      params.name = this.name //姓名
      params.sex = parseInt(this.sex) //性别
      // params.workUnit = this.workUnit //工作单位
      // params.workunitId = this.workunitId //工作单位id

      params.certifiMobile = this.certifiMobile //证件照片
      params.reversePicture = this.reversePicture //身份证反面照片
      params.frontPicture = this.frontPicture //身份证正面照片
      params.heldPhotos = this.heldPhotos //手持身份证照片
      params.auditStatus = (this.orgId && this.payStatus == 1) ? 4 : 1 //审核状态

      params.signupId = parseInt(this.signupId) //报名id
      params.signupUserId = parseInt(this.signupUserId) //考生id
      params.timeId = parseInt(this.timeId) //场次id
      params.userId = this.$store.state.userInfo.userId //用户id
      params.configId = parseInt(this.configId) //考生id
      this.submitParams = params

      // 申请报名需要校验是否需要补考
      if (this.type == 1 || (this.orgId && this.payStatus == 1)) {  // 申请报名
        this.getIsMake();
      } else if (this.type == 2) {  // 修改报名
        // 提交报名信息
        this.onSubmit();
      }
    },

    // 是否需要补考
    async getIsMake() {
      this.loading = true
      let userId = this.$store.state.userInfo.userId;
      await this.$ajax({
        url: '/hxclass-pc/exam/verify/make-up-exam',
        method: "get",
        params: {
          signupId: this.signupId,
          userId: userId,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          // 不用缴纳补考费，直接提交报名信息
          this.isMake = false;
          this.onSubmit();
        } else {
          // 需要补考
          this.confirmTxt = res.message;
          this.isMake = true;
          this.confirmPopup = true;
        }
      });
    },

    // 提交报名信息
    onSubmit(){
      // 上锁
      if (!!this.lock) {return}
      this.lock = true; //打开锁

      let url = ''
      let method = ''
      // 新增
      if (this.type == 1) {
        // 需要补考
        if (!!this.isMake) {
          url = '/hxclass-pc/exam/signUp/makeUpSave'
        } else {
          url = '/hxclass-pc/exam/signup/save'
        }
        method = "POST"
      } 
      // 修改
      else if (this.type == 2) {
        url = '/hxclass-pc/exam/signup/edit'
        method = "PUT"
      }

      // 报名
      this.$ajax({
        url,
        method,
        params: this.submitParams
      }).then((res) => {
        this.lock = false
        if (res.code == 200 && res.success) {
          // 新增
          if (this.type == 1) {
            // 需要补考
            if (!!this.isMake) {
              this.signupUserId = res.data; //用户报名id
              this.goPay()
            } else {
              // 提交成功
              this.showPopup = true;
              this.popupTxt = '考试申请将于'+ this.auditTime +'统一审核，如在该时间前未学完课程，本次考试申请将被驳回。'
            }
          } 
          // 修改补考    且需要缴纳补考费
          else if(this.type == 2 && this.orgId && this.payStatus == 1 && !!this.isMake) {
            this.goPay()
          } else {
            this.$message.success("修改成功");
            this.$router.go(-1)
          }
        } else {
            this.$message.warning(res.message);
            this.$router.go(-1)
        }
      });
    },
    goPay(){
      if(this.orderId){
        let url = window.location.origin + '/pay/payMethod?orderId=' + this.$AES.encode_data(this.orderId + '') + '&productType=' + this.$AES.encode_data(3 + '') + '&signupUserId=' + this.$AES.encode_data(this.signupUserId + '') + '&auditTime=' + this.auditTime
        window.open(url,'_self');

        this.confirmPopup = false
      }
      // 电子照片商品信息
      let productList = [
        {
          city:'',
          count: 1,
          noGift: 0,
          productId: this.configId,
          province: '',
          type: 7
        }
      ]
      if(this.payLoad || this.orderId){return}
      this.payLoad = true
      this.PayMessage = this.$message.loading('正在调起支付功能..', 0);
      // 获取订单信息
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/confirm/order',
        method:'POST',
        params: productList
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.onCreateOrder(res.data)
        } else {
          this.$message.error('支付调起失败');
          this.payLoad = false
        }
      })
    },
    // 生成订单
    onCreateOrder(e){
      let obj = e.productList[0]
      let orderForm = {
        couponIds: [],
        couponName: '',
        couponPrice: 0,
        freightPrice: 0,
        invoiceId: null,
        isInvoice: 0,
        orderPrice: obj.totalPrice,
        orderType: 3,
        payPrice: obj.totalPrice,
        productList: [
          {
            courseDeferralDaysNotes: '',
            discountPrice: '',
            examNumberNotes: '补考次数：'+ obj.makeNum +'次',
            kfxhMumber: 0,
            noPay: 0,
            openTimeId: null,
            otherNotes: '',
            payPrice: obj.totalPrice,
            picture: '',
            productCount: 1,
            productId: obj.productId,
            productPrice: obj.totalPrice,
            productType: 7,
            type: 1,
          }
        ],
        receiveId: '',
        signupUserId: this.signupUserId,
        source: '',
        sourceId: '',
      }
      this.$ajax({
        url:'/hxclass-pc/order/',
        method:'post',
        params:orderForm
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.orderId = res.data.orderId
          this.onRelevanceId(res.data.orderId)
        }else{
          this.$message.error('支付调起失败');
          this.payLoad = false
        }
      })
    },
    // 关联id
    onRelevanceId(orderId) {
      this.$ajax({
        url:"/hxclass-pc/exam/signUp/saveSignUpUserAndOrder",
        method:'put',
        params:{
          orderId: Number(orderId),
          signUpUserId: this.signupUserId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          let url = window.location.origin + '/pay/payMethod?orderId=' + this.$AES.encode_data(this.orderId + '') + '&productType=' + this.$AES.encode_data(3 + '') + '&signupUserId=' + this.$AES.encode_data(this.signupUserId + '') + '&auditTime=' + this.auditTime
          window.open(url,'_self');

          this.$message.success('成功');
          this.confirmPopup = false
        } else {
          this.$message.error('支付调起失败');
          this.payLoad = false
        }
      })
    },
    signUpOk(){
      this.showPopup = false
      this.$router.go(-1)
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 存储当前时间
    let year = new Date().getFullYear();
    let month =new Date().getMonth() + 1 < 10? "0" + (new Date().getMonth() + 1): new Date().getMonth() + 1;
    let date =new Date().getDate() < 10? "0" + new Date().getDate(): new Date().getDate();
    this.nowTime = year + '-' + month + '-' + date

    this.configId = this.$route.query.configId ? this.$AES.decode_data(this.$route.query.configId) : '';
    this.signupId = this.$route.query.signupId ? this.$AES.decode_data(this.$route.query.signupId) :'';
    this.timeId = this.$route.query.timeId ? this.$AES.decode_data(this.$route.query.timeId): '';
    this.type = this.$route.query.type ? this.$AES.decode_data(this.$route.query.type) : '';
    this.auditTime = this.$route.query.auditTime ? this.$AES.decode_data(this.$route.query.auditTime) : '';
    this.signupUserId = this.$route.query.signupUserId ? this.$AES.decode_data(this.$route.query.signupUserId) : '';
    this.isOnly = this.$route.query.dis ? this.$AES.decode_data(this.$route.query.dis) : '';

    this.getEducation()
  },
  // 生命周期-实例挂载后调用
  mounted () {
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}
.write-panel{
  background: #ffffff;
  padding: 20px 30px;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  // .sping{
  //   width: 20px;
  //   height: 20px;
  //   position: absolute;
  //   left: 50%;
  //   top: 250px;
  //   margin-top: -10px;
  //   margin-left: -10px;
  // }
  .title{
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
    &::before{
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #15B7DD;
      border-radius: 2px;
      margin-right: 4px;
      margin-bottom: -1px;
    }
  }
  .tips-note{
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #F90000;
    line-height: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F2F2F2;
  }
  .side-item{
    margin-top: 30px;
  }
  .personnel-info{
    margin-top: 24px;
    display: flex;
    .personnel-pic{
      margin-right: 60px;
      height: 168px;
      min-width: 130px;
      max-width: 130px;
      border: 1px solid #EBEBEB;
      cursor: pointer;
      .upload-pic{
        text-align: center;
        img{
          width: 38px;
          margin-top: 32px;
        }
        .tips{
          padding: 12px;
          margin-top: 20px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #F90000;
          line-height: 15px;
        }
      }

      .user-pic{
        width: 100%;
        height: 100%;
      }
    }
    .personnel-detail{
      display: flex;
      flex-wrap: wrap;
      .li-item{
        display: flex;
        align-items: center;
        margin-right: 24px;
        width: calc((100% - 100px) / 3 );
        margin-bottom: 25px;
        .li-title{
          display: inline-block;
          min-width: 65px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
        }
        .li-input{
          flex: 1;
        }
      }
    }
  }
  .idcard-info{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .idcard-item{
      text-align: center;
      width: calc((100% - 248px) / 3);
      .types-name{
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        i{
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          border-radius: 50%;
          font-style: normal;
          background: @theme;
          color: #ffffff;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
        }
      }
      .rubric{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-top: 12px;
      }
      .hold-pic{
        text-align: left;
        margin-left: 30px;
        p:nth-child(2){
          margin-top: 3px;
        }
        .rubric::before{
          content: '*';
          position: relative;
          top: 4px;
          color: #F90000;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .idcard-cover{
        height: 194px;
        margin-top: 20px;
        cursor: pointer;
        .idcard-img{
          width: 100%;
          height: 100%;
        }
      }
      .last-idcard-cover{
        margin-top: 0;
      }
    }
  }
  .submit-btn{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 100px;
    .cancel-btn{
      text-align: center;
      border-radius: 23px;
      color: @theme;
      line-height: 34px;
      border: 1px solid @theme;
      width: 114px;
      height: 34px;
      margin-right: 24px;
    }
    span{
      width: 114px;
      font-size: 14px;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
    }
  }
  .sping{
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
